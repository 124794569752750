.About {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  text-align: center;
}

.About h1.bp3-heading {
  margin-top: 30px;
  color: #005587;
  font-size: 56px;
  line-height: 56px;
  font-weight: 300;
}

.About .hero {
  display: block;
  max-width: 100%;
  margin: 20px auto 5px auto;
}

.About .available {
  display: inline-block;
  margin: 20px auto 30px auto;
  padding: 5px 30px;
  font-size: 28px;
  line-height: 30px;
  color: #ffd100;
  background-color: #005587;
}

.About .description {
  position: relative;
  width: 800px;
  margin: auto;
  text-align: left;
  font-size: 18px;
  line-height: 22px;
}

.About .app-icon {
  position: absolute;
  left: -120px;
  top: 0;
}

.About ul.description {
  padding-left: 30px;
}

.About .badges {
  margin: 30px auto;
  text-align: center;
}

.About .badges a:first-child {
  margin-right: 30px;
}

.About .badges img {
  height: 50px;
}

.About footer {
  font-size: 12px;
  color: #2774ae;
}

.About footer a {
  text-decoration: underline;
}

@media only screen and (max-width: 800px) {
  .About {
    padding-left: 10px;
    padding-right: 10px;
  }

  .About h1.bp3-heading {
    margin-top: 10px;
  }

  .About .available {
    margin-bottom: 15px;
  }

  .About .description {
    width: auto;
  }

  .About .app-icon {
    position: static;
    float: left;
    margin-right: 10px;
  }

  .About footer {
    text-align: center;
  }
}
