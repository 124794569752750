body {
  font-family: "proxima-nova", sans-serif;
  color: #222222;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

content {
  position: relative;
  flex: 1 1 auto;
  padding: 85px 15px 15px 15px;
}

footer {
  padding: 15px;
  text-align: left;
}

footer.light {
  color: white;
}

footer.light a {
  color: white;
  text-decoration: underline;
}

h1.bp3-heading {
  font-size: 28px;
}

h2.bp3-heading {
  font-size: 24px;
}

.bp3-heading {
  font-weight: normal;
  color: #005587;
}

@media only screen and (max-width: 767px) {
  content {
    padding: 85px 10px 10px 10px;
  }
}
